import styled from 'styled-components';
import React from 'react'
import Layout from "../components/layout"
import Seo from "../components/seo"

const SectionShowreelGroup = styled.div`
  color: black;
  font-weight: 600;
  font-size: 24px;
  padding: 30px 30px 20px 30px;
`;

export default function GraphicDesignpage() {
  return (
    <Layout>
    <Seo title="Graphic Design" />
    <div className="Hero">
      <div className="HeroGroup">
        <SectionShowreelGroup>
          <h2>Graphic Design</h2>
        </SectionShowreelGroup>
      </div>
    </div>
    </Layout>
  );
}
